import React from 'react'

import Container from './Container'
import GridContainer from './GridContainer'
import SectionContainer from './SectionContainer'

const ReusableGrid = ({ children, className = '' }) => {
  return (
    <SectionContainer>
      <Container>
        <GridContainer className={`${className}`}>{children}</GridContainer>
      </Container>
    </SectionContainer>
  )
}

export default ReusableGrid
