// import { MAX_TICKET_TYPES_PER_EVENT } from 'consts'

// SYSTEM MESSAGES
const MSG = {
  ERR: {
    // SIGN UP
    INVALID_EMAIL: 'Invalid email',
    INVALID_PASSWORD: 'Invalid password',
    INVALID_FIRST_NAME: 'Invalid first name',
    INVALID_LAST_NAME: 'Invalid last name',
    INVALID_PHONE_NUMBER: 'Invalid phone number',
    INVALID_ADDRESS: 'Invalid address',
    INVALID_ZIP_CODE: 'Invalid zip code',

    // SIGN IN
    INVALID_CREDENTIALS: 'Invalid login credentials',

    // PURCHASE TICKETS
    INVALID_CARD_NUMBER: 'Invalid card number',
    INVALID_CARD_EXPIRY: 'Invalid card expiry',
    INVALID_CARD_CVC: 'Invalid card cvc',
    PURCHASE_FAILED: 'Purchase failed',

    // CANCELLATION
    INVALID_ORDER: `Error cancelling order. Please contact ${process.env.NEXT_PUBLIC_SUPPORT_EMAIL}.`,

    // SEND EMAIL
    MISSING_PARAMS: 'Missing params. Check emailAddr, subject, and htmlBody',
    //HOST ONBOARD EMAIL
    MISSING_ONBOARD_PARAMS: 'Missing params. Check hostName and email address',

    // HEADER
    REQUEST_OPTION_ERR: 'Error while creating RequestOptions',

    // CLOUD_FUNCTIONS
    CF_ORDER_CONF: 'Error: Cannot reach to the CF-Order confirmation.',
    // CF_ORDER_CONF_TOAST: 'Connectivity Issue\nError Code: 4000',
    CF_ORDER_CONF_TOAST: 'Error while sending email. Please contact support.',

    //Dom query selector
    INVALID_QUERY_SELECTOR: 'Error selecting dom element.Please check if the id or class exists',
  },

  SUCCESS: {
    // CLOUD_FUNCTIONS
    CF: {},
    CF_ORDER_CONF_EMAIL: 'Order confirmation email sent successfully.',
    GB_SESSAMI_PARTNER_PROG: 'Welcome to Sessami Partners Program!',
    GUEST: {
      CHECK_INBOX: 'Please check your inbox now.',
    },
    BUYER: {
      LINK_COPY_TO_CB: 'Link copied to clipboard',
      LINK_UPD: 'Links Updated!',
      IMAGE_UPLOADED: 'Profile image updated',
      COVER_IMAGE_UPLOADED: 'Cover image updated',
      EMAIL_SENT: 'Email Sent! Check your mailbox.',
      SLACK_NOTIFY: "Thank you for your message!\nWe'll get back to you shortly.",
      USER_PROFILE_UPD: 'User information updated!',
      EMAIL_PREFERENCE_UPD: 'Email preferences updated!',
      TRANSACTION_SUCCESS: 'Transaction Successful!',
      ORDER_CANCELLED: 'Order cancelled',
      DELETE_USER: 'User has been deleted',
    },
    SELLER: {
      COPY_TO_CB: 'Copied to clipboard',
      CHECKED_IN: 'checked in!',
      NEW_VENUE: 'New Venue Added!',
      WAITLIST_JOINED: 'You are on the waiting list',
      EVENT_DUPLICATED: 'Event Duplicated Successfully!',
      TICKET_UPD: 'Tickets Updated!',
      TICKET_GRP: 'Tickets Group Updated!',
      GUEST_ADD: 'Guest Added Successfully!',
      GUEST_UPD: 'Guest Updated Successfully!',
      PWD_CHANGED: 'Password changed successfully',
      SEAT_PLAN_ADDED: 'Seatplan added successfully',
      HOST_INFO_UPD: 'Host information updated!',
      EVENT: {
        PUBLISHED: 'Event published!',
        UNPUBLISHED: 'Event unpublished!',
        UPDATED: 'Event Updated Successfully!',
        CREATED: 'Event Created Successfully!',
        DELETED: 'Event Deleted Successfully!',
        ARCHIVED: 'Event Archived Successfully!',
        RESERVED: 'Tickets Reserved Successfully!',
      },
      CAMPAIGN: {
        DELETED: 'Campaign Deleted Successfully!',
        RECIPIENTS_COPIED: 'Recipients Copied Successfully !',
      },
      ALL_IMAGE_DELETED: 'All Images Deleted Successfully',
      IMAGE_DELETED: 'Image Deleted Successfully',
      ORDER: {
        FREE_ORDERS_CANCELLED: 'Selected Free Order(s) Cancelled Successfully!',
      },
      BRANDING_UPDATED: 'Branding colors updated successfully',
    },
  },

  INFO: {
    COMING_SOON: 'Coming Soon!',
    FEATURE_REQUEST: `Write to us at ${process.env.NEXT_PUBLIC_SUPPORT_EMAIL} if you need this feature.`,
    COOKIE_UPDATE: 'Cookie preference updated successfully!',
    VERIFY_ACCOUNT: `Please to email us at ${process.env.NEXT_PUBLIC_SUPPORT_EMAIL}  with relevant documents to get a badge.`,
    GUEST: {
      VERIFY_EMAIL: `Verification email sent successfully!\n`,
    },
    BUYER: {
      TICKET_SOLD_EARLIER:
        'Oops! It seems someone beat you to it. The ticket/s you had in your cart have been sold. Better luck next time!',
      BOOKING_TIME_OUT: 'You ran out of time. Refreshing page in 5 seconds',
      PAYMENT_SUCCESS: 'Payment Successful!\nGenerating Tickets',
      TICKET: {
        DISCOUNT_APPLIED: '£5 off applied per ticket',
        FREE: 'You are eligible for free tickets',
        CARTTOTAL_ZERO: 'No tickets found in cart ! Please reconsider to add new tickets',
      },
      MIN_ONE_TICKET: 'Please add atleast 1 ticket to continue!',
      SIGN_UP_SUGGESTION: 'Try Sessami for free and Explore new exciting events nearby.',
      NO_EVENT_NEARBY: {
        TITLE: 'No events Nearby',
        MESSAGE:
          'Currently, there are no events nearby you. It appears that you are extremely far from our service zone. We apologize for any inconvenience and appreciate your understanding.',
      },
    },
    SELLER: {
      ALREADY_IN_WAITLIST: 'You are already on the waiting list',
      UNPUBLISH_IN_DELETION: 'Your event is unpublished for now ',
      SELECT_AT_LEAST_A_FREETICKET_TYPE: 'Please select a free ticket type',
      NO_FREE_TICKETS_IN_CANCELLATION: 'No Free Tickets selected for cancellation!',
      EVENTS: {
        DELETE_FREE_TICKETS_SOLD:
          'Please cancel all the Free tickets from the Guestlist first. Cancellation notifies all the buyers. Then you can proceed with Event Deletion.',
        ARCHIVE_PAID_EVENT:
          'Event which generated revenue cannot be deleted due to Archival reasons. Archiving leads to removal of event from the Marketplace only, thus stopping sale.',
        ARCHIVE_FREE_EVENT:
          'Archiving leads to removal of event from the Marketplace only, thus stopping sale.',
        ARCHIVE_MIXED_EVENT:
          'As this event has both free and paid tickets sold, please begin by canceling all free tickets from the Guestlist. Once this is done, you can proceed with further steps for event deletion.',
        REFUND_FEATURE:
          'Note: We currently do not support Refund for paid tickets. It will be available in Q2 2024. ',
      },
    },
  },

  ERROR: {
    OPERATION_FAILED: 'Something went wrong. Please Try Again!',
    GUEST: {
      ALREADY_IN_USE: 'This email is already in use. Try Forget Password to regain access.',
      TRY_AGAIN: 'Please try again in a few minutes!',
      SIGN_UP: {
        TERMS: 'You need to agree to our terms to use Sessami',
        PASSWORD_DIDNT_MATCH: 'passwords do not match, please check and try again',
        PASSWORD_MIN_6: 'Please enter a password of at least 6 characters.',
      },
    },
    BUYER: {
      USER_DELETE_ERR: 'Something went wrong while deactivating user',
      STRIPE_CONNECTION: 'Something went wrong with Stripe Connection!',
      RYFT_CONNECTION: 'Something went wrong with Ryft Pay Connection!',
      ERR_GENERATING_TICKET: 'Something went wrong while generating tickets!',
      UNSUPPORTED_IMAGE: 'Unsupported image format. Please choose JPEG, PNG, JPG or WEBP images.',
      PAYMENT_PROCESSING: 'Something went wrong while processing payment!',

      INCORRECT_PWD: 'Old password is Incorrect',
      INCORRECT_PASSWORD: 'Incorrect password',
      SLACK_NOTIFY_ERR: 'Error submitting form. Please try again later.',
      FETCH_ORDER_BY_EVENTID: 'Error fetching orders for current Event.',
      FETCH_ORDER_DETAILS: 'Error fetching order details!',
      FETCH_EVENT_DETAILS: 'Error fetching event details!',
      MIN_ONE_TICKET: 'Please add atleast 1 ticket to cart or a minimum order of £1.00',
      EVENT_SOLD_OUT: 'Sorry, this event is sold out!',
      EVENT_ENDED: 'Sorry, this event has ended!',
      INVALID_FILE: 'Invalid file. Only supports JPEG/JPG/PNG file',
      NO_FILE_SELECTED: 'No file selected',
      ACCOUNT_NOT_VALID: 'Something went wrong! Try logging out and back in.',
    },
    SELLER: {
      FETCHING_SCANNER_EVENTS: 'Failed to fetch scanner events',
      RESERVATION_FAILED: 'failed to reserve ticket',
      VENUE_ADDITION_FAILED: 'Failed to add venue!',
      MAX_TICKET_TYPE_CT: 'A Maximum of 10 Ticket Types allowed currently!',
      // MAX_TICKET_TYPE_CT: `A Maximum of ${MAX_TICKET_TYPES_PER_EVENT} Ticket Types allowed currently!`,
      MIN_TICKET_TYPE_CT: 'A minimum of one ticket type is required.',
      GPT_NO_RESPONSE: 'No response from GPT-3',
      ERR_ADDING_SEAT_PLAN: 'Error adding seatplan',
      EMAIL_SENT_ALREADY: 'An email has already been sent to this person!',
      CANNOT_PUBLISH_EVENT_WITHOUT: {
        NO_TICKET: 'You cannot publish an event without configuring tickets first!',
        VENUE: 'You cannot publish an event without adding a venue!',
        SOLD_TICKET: 'You cannot unpublish an event with sold tickets!',
      },
      CAMPAIGN: {
        CAMPAIGN_DELETION_FAILED: 'Error while deleting campaign !',
      },
      SCAN_NOT_ALLOWED: 'Scan Not Allowed',
      SCAN_NO_MATCH: 'The QR does not match with any of your Event Tickets.',
      COULD_NOT_FIND_ORDER: 'Could not find the order in the database.',
      INVALID_QR: 'Invalid QR Code',
      EVENT_DELETION_FAIL: 'Error Deleting the event!',
      EVENT_UNPUBLISHING_FAIL: 'Error Unpublishing the event!',
      EVENT_ARCHIVAL_FAIL: 'Error Archiving the event!',
      IMAGE_DELETION_FAIL: 'Error while deleting the image!',
      FAILED_EMAIL_FETCH: 'Error while fetching the guests email !',
      ORDER: {
        FREEORDERS_CANCELLED_ERROR: 'Error on cancelling free orders',
      },
    },
  },

  WARNING: {
    GUEST: {},
    BUYER: {
      EMAIL_ALREADY_SENT_LIMIT: 'Email already sent. Please wait 5 mins before sending again.',
    },
    SELLER: {
      DELETE_HOST: 'Host cannot delete his/her account',
      ALREADY_CHECKED_IN: 'Already checked in',
      ALREADY_CANCELLED: 'Already Cancelled ticket',
      WAITLIST_REMOVED: 'You have been Unlisted at your request.',
    },
  },

  CONFIRM: {
    REDIRECT_FORM_INCOMPLETE: {
      MSG: 'You have unsaved changes. Are you sure you want to leave?',
    },
    GUEST: {},
    BUYER: {
      DELETE_USER: {
        TITLE: 'Delete user',
        MESSAGE: 'Are you sure you want to delete this guest? ',
      },
      CANCEL_ORDER: {
        TITLE: 'Cancel order',
        MESSAGE: 'Are you sure you want to cancel this order ?',
      },
      LOG_OUT: {
        TITLE: 'See you soon...',
        MESSAGE: 'Are you sure you want to log out?',
      },
    },
    SELLER: {
      EVENT_SKIP: {
        TITLE: 'Confirm Skip',
        MESSAGE:
          'Events wont be published without tickets. They will stay under Draft Events.\nAre you sure you want to skip ? ',
      },
      EVENT_IS_PUBLISHED: {
        PUBLISHED: {
          TITLE: 'Confirm Publish?',
          MESSAGE: 'Are you sure you want to Publish the event?',
        },
        UNPUBLISHED: {
          TITLE: 'Confirm UnPublish?',
          MESSAGE: 'Are you sure you want to Unpublish the event?',
        },
      },
      DELETE_TICKET: {
        TITLE: 'Confirm Deletion ?',
        MESSAGE: 'Are you sure you want to Delete the ticket ?',
      },
      DELETE_EVENT: {
        TITLE: 'Confirm Deletion?',
        MESSAGE: 'Are you sure you want to Delete the event? This is irreversible!',
      },
      ARCHIVE_EVENT: {
        TITLE: 'Confirm Archival?',
        MESSAGE: 'Are you sure you want to Archive the event?',
      },
      DELETE_CAMPAIGN: {
        TITLE: 'Confirm Deletion ?',
        MESSAGE: 'Are you sure you want to Delete the campagin? This is irreversible !',
      },
    },
  },
}

export default MSG
