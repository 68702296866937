const ROUTES = {
  // PUBLIC ROUTES
  // ANY: '*', // This is a catch all route
  // ROOT: '/',
  // ERROR: '/404',
  // LOG_IN: '/log-in',
  // SIGN_UP: '/sign-up',

  // SEO
  SITEMAP: '/site-map', // Note: This is a UI route. Goto /sitemap.xml for the actual sitemap xml.
  // Dev
  GRID_OVERLAY: '/grid-overlay',

  // PUBLIC ROUTES
  ANY: '*', // This is a catch all route
  ROOT: '/',
  ERROR: '/404',
  EXPLORE: '/explore',
  LOG_IN: '/log-in',
  LOG_OUT: '/log-out',
  SIGN_UP: '/sign-up',
  LANDING: '/landing',
  HELP: '/help',
  HOSTING: {
    HOST_YOUR_EVENT: '/hosting/host-your-event',
    SELL_TICKETS_ONLINE: '/hosting/sell-online',
    HOST_RESPONSIBLY: '/hosting/host-responsibly',
    HOSTING_RESOURCES: '/hosting/hosting-resources',
    EVENT_PLANNING: '/hosting/event-planning',
    FOR_HOSTS: '/hosting/for-hosts',
  },
  FEATURES: {
    DISCOVER: '/features/discover',
    TICKETING: '/features/ticketing',
    ATTENDEE_MANAGEMENT: '/features/attendee-management',
    GROWTH_AND_MARKETING: '/features/growth-and-marketing',
    PRICING_COMPARISON: '/features/pricing',
  },
  SESSAMI: {
    ABOUT_US: '/about',
    CONTACT: '/contact',
    CAREERS: '/careers',
    INVESTORS: '/investors',
  },
  RESOURCES: {
    BLOG: '/blogs',
    GUIDES: '/guides',
    CASE_STUDIES: '/case-studies',
    FAQS: '/faqs',
    NEWS_CENTER: '/news-center',
    HELP: '/help',
  },
  LEGAL: {
    PRIVACY_POLICY: '/legal/privacy-policy',
    TERMS_OF_USE: '/legal/terms-of-use',
    BILLING: '/legal/billing',
    COOKIE_POLICY: '/legal/cookie-policy',
    BADGE_VERIFICATION_REQUIREMENTS: '/legal/badge-verification-requirements',
  },

  ONBOARDING: '/h/onboarding',
  FORGOT_PASSWORD: '/forgot-password',
  HOSTS_LANDING: '/hosts',
  FOR_GUESTS: '/for-guests',
  CONTACT: '/contact',
  GET_THE_APP: '/get-the-app',
  ABOUT_US: '/about',
  PRICING: '/pricing',
  FAQS: '/faqs',
  FEEDBACK: '/u/feedback',

  // PSEUDO PRIVATE ROUTES
  BOOKING_WIDGET: '/hosts/:hostId',
  EVENT_BOOKING: '/events/:eventId',

  // PRIVATE ROUTES

  // User Authenticated Routes
  USER_DASHBOARD: '/u/dashboard',
  USER_TICKETS: '/u/tickets',
  USER_TICKET_DETAIL: '/u/tickets/:ticketId',

  // Settings
  USER_PROFILE: '/u/settings/profile',
  USER_SETTINGS: '/u/settings/security',
  USER_CUSTOMER_SUPPORT: '/u/customer-support',
  USER_DELETE_ACCOUNT: '/u/delete-account',
  USER_MARKETING_PERMS: '/u/marketing-permissions',
  USER_FEEDBACK: '/u/feedback',

  // Host Authenticated Routes
  DASHBOARD: '/h/dashboard',
  SCAN: '/h/scan',
  GUESTS: '/h/guests',
  GUESTS_ADD: '/h/guests/addguest',
  GUESTS_EDIT: '/h/guests/edit/:guestId',
  TICKETS: '/h/tickets',
  ONLINE_PROFILE: '/h/onlineprofile',
  WAITLISTS: '/h/waitlists',

  // HOST AUTHENTICATED- Events
  EVENTS: '/h/events',
  EVENT_NEW: '/h/events/new',
  EVENT_EDIT: '/h/events/:eventId/edit',
  EVENT_OVERVIEW: '/h/events/:eventId/overview',
  EVENT_TICKETS: '/h/events/:eventId/tickets',
  EVENT_SURVEY: '/h/events/:eventId/survey',
  EVENT_SCAN: '/h/events/:eventId/scan',
  EVENT_SCANNERS: '/h/events/:eventId/scanners',
  EVENT_GUESTS: '/h/events/:eventId/guests',
  EVENT_WAITLIST: '/h/events/:eventId/waitlist',
  EVENT_SHARE: '/h/events/:eventId/share',

  // DISCOUNTS
  DISCOUNTS: '/h/discounts',
  DISCOUNT_NEW: '/h/discounts/new',
  DISCOUNT_DETAIL: '/h/discounts/:discountId',
  // DISCOUNT_EDIT: '/h/discounts/:discountId/edit',

  // HOST - Venues
  VENUES: '/h/venues',
  VENUE_ADD: '/h/venues/add',
  VENUE_OVERVIEW: '/h/venues/:venueId/overview',
  VENUE_SEATPLAN: '/h/venues/:venueId/seatplan',

  // HOST - Reports
  REPORTS: '/h/reports',
  REPORTS_OVERVIEW: '/h/reports/overview',
  REPORTS_ORDERS: '/h/reports/orders',

  // HOST - Promotions
  PROMOTE: '/h/promote',
  EMAIL: '/h/marketing/email',
  EMAIL_CAMPAIGN_EDIT: '/h/promote/email',
  EMAIL_EDITOR: '/h/promote/email/editor',
  EMAIL_DETAILS: '/h/promote/email/details',
  EMAIL_AUDIENCE: '/h/promote/email/audience',
  EMAIL_COST: '/h/promote/email/campaign-cost',

  EMAIL_RECIPIENTS: '/h/marketing/email/recipients',
  SHARE: '/h/promote/:hostId/share',
  EMAIL_CAMPAIGN: '/h/promote/campaign-channel',

  //HOST - Comments
  COMMENTS: '/h/comments',

  // HOST - Settings
  SETTINGS: '/h/settings',
  SETTINGS_PROFILE: '/h/settings/profile',
  SETTINGS_PAYOUTS: '/h/settings/payouts',
  SETTINGS_COLORS: '/h/settings/colors',
  SETTINGS_PERMISSIONS: '/h/settings/permissions',
  SETTINGS_VERIFY_ACCOUNT: '/h/settings/verify-account',
}

export default ROUTES
