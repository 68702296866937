// Cloud Functions Endpoints
import getCFUrl from 'utils/CloudFunctions';


const CF_BASE_EW = {
  EMULATOR: 'http://127.0.0.1:5001/sessami-dev/europe-west2',
  DEVELOPMENT: 'https://europe-west2-sessami-dev.cloudfunctions.net',
  PRODUCTION: 'https://europe-west2-sessami-production.cloudfunctions.net',
}

const CLOUD_FUNCTIONS = {
  GET_CLIENT_SECRET: getCFUrl('getSessionClientSecret', CF_BASE_EW),
  GET_CLIENT_SECRET_V2: getCFUrl('getSessionClientSecretv2', CF_BASE_EW),
  SEND_ORDER_CONFIRMATION_EMAIL: getCFUrl('sendOrderConfirmationEmail', CF_BASE_EW),
  SEND_GENERIC_EMAIL: getCFUrl('sendGenericEmail', CF_BASE_EW),
  SEND_ORDER_SHARE_EMAIL: getCFUrl('sendOrderShareEmail', CF_BASE_EW),
  SEND_HOST_ONBOARDING_EMAIL: getCFUrl('sendHostOnboardingEmail', CF_BASE_EW),
  GET_CHATGPT_RESPONSE: getCFUrl('getChatGPTResponse', CF_BASE_EW),
  GET_USERS_BY_HOST_ID: getCFUrl('getUsersByHostId', CF_BASE_EW),
  CANCEL_ALL_ORDERS: getCFUrl('cancelAllOrdersByEventId', CF_BASE_EW),
  CANCEL_EVENT_BY_ID: getCFUrl('cancelEventById', CF_BASE_EW),
}

export { CF_BASE_EW, CLOUD_FUNCTIONS }