// ------------- UI Reducer -------------
import { createSlice } from '@reduxjs/toolkit'
import { HYDRATE } from 'next-redux-wrapper'

const defaultSort = { id: '', desc: {} }

const defaultOrderSelection = { name: false }

const commonColumnSelection = {
  eventName: false,
  eventRsvp: false,
  eventDate: false,
  eventImage: false,
}

const defaultEventsColumnSelection = {
  ...commonColumnSelection,
  price: false,
  revenue: false,
  ['summary.fees']: false,
  venueName: false,
  venueAddress: false,
  freeOrders: false,
  freeTickets: false,
  totalOrders: false,
  paidOrders: false,
  paidTickets: false,
  isArchived: false,
  isPrivate: false,
}

const defaultGuestListColumns = {
  ...commonColumnSelection,
  tickets: false,
  paymentProcessor: false,
  cancelledBy: false,
  isCancelled: false,
  isReserved: false,
  isRefunded: false,
  fee: false,
  subTotal: false,
  grandTotal: false,
  trafficSource: false,
}

const defaultRecentOrderSelection = {
  ...defaultGuestListColumns,
  eventImage: false,
  venueName: false,
  venueAddress: false,
  eventName: true,
}

const defaultMasterWaitlistColumnsSelection = {
  name: false,
  hostName: false,
}

const initialState = {
  // Root Level
  eventList: {
    search: '',
    sort: [{ ...defaultSort }],
    range: 'future',
    column: { ...defaultEventsColumnSelection },
    columnPinning: { left: ['name'] },
  },

  masterGuestList: {
    search: '',
    sort: [{ ...defaultSort }],
    column: { ...defaultGuestListColumns },
    columnPinning: { left: ['select', 'name'] },
  },
  masterWaitList: {
    search: '',
    sort: [{ ...defaultSort }],
    column: { ...defaultMasterWaitlistColumnsSelection },
    columnPinning: { left: ['select', 'name'] },
  },
  masterOrderList: {
    search: '',
    sort: [{ ...defaultOrderSelection }],
    column: { ...defaultRecentOrderSelection },
  },

  // Event Level
  eventWaitList: {
    search: '',
    sort: [{ ...defaultSort }],
    column: {
      email: false,
    },
    columnPinning: { left: ['select', 'name'] },
  },
  eventGuestList: {
    search: '',
    sort: [{ ...defaultSort }],
    column: {},
    columnPinning: { left: ['select', 'name', 'email'] },
  },
  ticketList: {
    search: '',
    sort: [{ ...defaultSort }],
    column: {},
  },
  venueList: {
    search: '',
    sort: [{ ...defaultSort }],
    column: {},
  },
}

export const tableSlice = createSlice({
  name: 'table',
  initialState,
  reducers: {
    setTableFilters: (state, action) => ({
      ...state,
      table: {
        ...state.table,
        [action.payload.table]: action.payload.userPreference,
      },
    }),
    resetTables: () => initialState,
  },

  extraReducers: builder => {
    builder.addCase(HYDRATE, (state, action) => {
      return {
        ...state,
        ...action.payload,
      }
    })
  },
})

export const { setTableFilters, resetTables } = tableSlice.actions

export default tableSlice.reducer
